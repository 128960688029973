<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="portfolio !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeletePortfolio"
      >
        <span>آیا از حذف کردن این نمونه کار اطمینان دارید ؟ </span>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="10" md="12">
              <debouncer :placeHolder="`جستجو بر اساس نام نمونه کار`" @setValue="getsearchValue"></debouncer>
            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>


          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="portfolio"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(portfolioId)="data">
            <b-avatar v-if="data.item.files &&data.item.files.length>0 "
                      :src="baseURL+'media/gallery/portfolio/'+data.item.files[0].pictureUrl" size="100"
                      square/>
            <span class="text-muted ml-2">@{{ data.item.portfolioId }}</span>
          </template>

          <template #cell(full)="data">
            <div class="d-flex align-items-center gap-2">
              <span>{{ data.item.title }}</span>
            </div>
          </template>

          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedPortfolio(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <b-link :to="{ name: 'pages-portfolio-portfolioEdit', params: { id: data.item.portfolioId } }"
                      class="cursor-pointer d-flex flex-row"


              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </b-link>
            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {GetAllPortfolio, DeletePortfolio} from "@/libs/Api/portfolio";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست نمونه کار ها  - پنل ادمین انزا مد',
  name: 'PortfolioList',
  data() {
    return {
      pic: null,
      baseURL: Helper.baseUrl,
      showOverlay: false,
      portfolio: null,
      totalCount: null,
      sortBy: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'portfolioId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'full',
          label: 'موضوع',
          sortable: true
        },
        {
          key: 'description',
          label: 'توضیحات',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
      currentPage: 1,
      perPage: 10,
      search: '',
      selectedPortfolio: null,
    }
  },
  async created() {
    await this.GetAllPortfolio()
  },
  components: {
    Debouncer,
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  watch: {
    search: function () {
      this.GetAllPortfolio()
    },
    currentPage: function () {
      this.GetAllPortfolio()
    }
  },

  methods: {
    getsearchValue(val){
      this.search = val
      this.GetAllPortfolio()

    },
    async DeletePortfolio() {
      let _this = this
      let deletePortfolio = new DeletePortfolio(_this)
      deletePortfolio.setParams({id: this.SelectedPortfolio.portfolioId})
      await deletePortfolio.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `نمونه کار  حذف شد.`,
          },
        })
        _this.GetAllPortfolio();
      }, function (error) {
        console.log(error)
      })
    },
    SetSelectedPortfolio(item) {
      this.SelectedPortfolio = JSON.parse(JSON.stringify(item))
    },
    async GetAllPortfolio() {
      let _this = this
      let getAllPortfolio = new GetAllPortfolio(_this)
      let data = {
        pageNumber: _this.currentPage,
        searchCommand: _this.search,
        count: 10,
        vendorUserId: getUserData().userId
      }
      getAllPortfolio.setParams(data)
      await getAllPortfolio.fetch(function (content) {
        _this.portfolio = content.portfolios
        _this.totalCount = content.portfoliosCount
      }, function (error) {
        console.log(error)
      })
    },

  },
}
</script>

<style scoped>

</style>
